<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="sliderItemID">
                {{ sprintf($t('pages.module.sliderItem.save.editSliderItem'), [sliderItem.translate.title ?? '']) }}
            </template>
            <template v-else>{{ sprintf($t('pages.module.sliderItem.save.newSliderItem'), [sliderGroup.alias ?? '']) }}</template>
        </h2>

        <div class="d-flex flex-wrap my-1">
            <router-link :to="'/module/slider/' + this.sliderGroupID + '/item'" class="btn btn-primary align-self-center ms-4 back-page-btn">
                <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
                </span>
                {{ sprintf($t('pages.module.sliderItem.titlePattern'), [sliderGroup.alias ?? '']) }}
            </router-link>
        </div>
    </div>

    <el-tabs v-model="tabModel" class="slider-item-tabs">
        <el-tab-pane :label="$t('pages.module.sliderItem.save.tabs.generalDetails')" name="general">
            <el-form :model="form" ref="sliderItemForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.sliderItem.cols.title') }}</label>
                                    <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.translate.title" type="text" maxlength="256"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.sliderItem.save.cols.slug') }}</label>
                                    <el-form-item prop="translate.slug">
                                        <el-input v-model="form.translate.slug" type="text" maxlength="256"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.sliderItem.save.cols.shortTitle') }}</label>
                                    <el-form-item prop="translate.short_title">
                                        <el-input v-model="form.translate.short_title" type="text" maxlength="256"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.sliderItem.save.cols.description') }}</label>
                                    <el-form-item prop="translate.description">
                                        <el-input v-model="form.translate.description" type="textarea" rows="3" maxlength="1024"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.sliderItem.save.cols.image') }}</label>
                                    <el-form-item prop="data.image_id">
                                        <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage"
                                                   list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                            <i class="bi bi-plus"/>
                                        </el-upload>
                                        <el-dialog v-model="image.dialogVisible" width="30%">
                                            <img style="width: 100%" :src="image.dialogImageUrl" alt=""/>
                                        </el-dialog>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.popup.cols.startDate') }}</label>
                                    <el-form-item prop="data.start_date">
                                        <el-date-picker
                                            class="rangeDateTimePicker"
                                            v-model="form.data.start_date"
                                            type="datetime"
                                            popper-class="rangeDateTimePickerPopper"
                                            :placeholder="$t('common.chooseDateAndTime')"
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            :disabledDate="disabledStartDate"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.popup.cols.endDate') }}</label>
                                    <el-form-item prop="data.end_date">
                                        <el-date-picker
                                            class="rangeDateTimePicker"
                                            v-model="form.data.end_date"
                                            type="datetime"
                                            popper-class="rangeDateTimePickerPopper"
                                            :placeholder="$t('common.chooseDateAndTime')"
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            :disabledDate="disabledEndDate"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                    <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.sort" :min="1"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                    <el-form-item prop="data.active">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.module.sliderItem.save.tabs.buttonSettings')" name="buttonSettings">
            <Button :sliderItemID="sliderItemID" v-if="tabModel == 'buttonSettings'"></Button>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import CustomTable from "@/components/custom-table";
import Button from "@/components/module/slider/item/Button";

export default {
    name: "_id",
    components: {
        CustomTable,
        Button
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id
                },
                data: {
                    group_id: this.$route.params.id,
                    sort: 1,
                    active: true
                }
            },
            sliderItem: {
                translate: {}
            },
            sliderGroup: {},
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            },
        }
    },
    computed: {
        sliderGroupID() {
            return this.$route.params.id;
        },
        sliderItemID() {
            return this.$route.params.itemID;
        },
    },
    created() {
        if (!this.sliderGroupID || !(this.sliderGroupID > 0)) {
            this.$router.push({
                path: "/module/slider"
            });
        }

        if (this.sliderItemID && !(this.sliderItemID > 0)) {
            this.$router.push({
                path: "/module/slider/" + this.sliderGroupID + "/item"
            });
        }
    },
    mounted() {
        if(this.sliderGroupID && this.sliderGroupID > 0){
            this.loadSliderGroup();
        }

        if (this.sliderItemID && this.sliderItemID > 0) {
            this.loadSliderItem();
        }
    },
    methods: {
        loadSliderGroup() {
            this.axios.get(this.endpoints['module_slider_group'] + '/' + this.sliderGroupID).then((response) => {
                this.sliderGroup = response.data.data;
            })
        },
        loadSliderItem(sliderItemID = this.sliderItemID) {
            this.resetImageData();

            this.axios.get(this.endpoints['module_slider_item'] + '/' + sliderItemID).then((response) => {
                let data = response.data.data;

                data.start_date = data.start_date ? this.$moment(data.start_date).format('YYYY-MM-DD HH:mm:ss') : undefined;
                data.end_date = data.end_date ? this.$moment(data.end_date).format('YYYY-MM-DD HH:mm:ss') : undefined;

                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                this.image.fileList = [
                    {
                        name: data.image.name,
                        url: data.image.public_url
                    }
                ]

                this.sliderItem = data;

                this.form.translate = this.cloneData(data.translate);

                this.form.updateStatus = true;
                this.form.data = data;
            })
        },
        onSubmit() {
            this.$refs.sliderItemForm.validate((valid) => {
                if (valid) {
                    if(!this.image.fileList.length > 0){
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.module.sliderItem.save.warnings.requiredImage"),
                        })

                        return false;
                    }

                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.image.uploadList = [];
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            let formData = this.prepareFormData();

            if (this.form.data.id) {
                this.axios.put(this.endpoints['module_slider_item'] + '/' + this.form.data.id, formData).then(response => {
                    this.onResponse(response.data, () => {}, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['module_slider_item'], formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.$router.push({
                            path: "/module/slider/" + this.sliderGroupID + "/item/save/" + response.data.data.id
                        });
                        this.loadSliderItem(response.data.data.id);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        prepareFormData() {
            let formData = this.form.data;

            return {...this.form.translate, ...formData}
        },
        disabledStartDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.end_date ? this.$moment(this.form.data.end_date) : undefined;

            return this.$moment().add(-1, 'days').valueOf() > startDate.valueOf() || (endDate != undefined && startDate.valueOf() > endDate.valueOf());
        },
        disabledEndDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.start_date ? this.$moment(this.$moment(this.form.data.start_date).format('YYYY-MM-DD')) : this.$moment().add(-1, 'days');
            return startDate.valueOf() > endDate.valueOf();
        },
        resetImageData() {
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList) {
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.back-page-btn {
    height: 40px;
    line-height: 1.25;
}

.rangeDateTimePickerPopper .el-button--text.el-picker-panel__link-btn {
    display: none;
}
</style>